<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="촬영실/녹음실 예약 수정">
            - 회사 내부 사용은 회사명을 캐럿솔루션즈/캐럿글로벌/밝음으로 선택해주시기 바랍니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 강의실</th>
                                <td>
                                    <select class="w-200px" v-model="mod.info.studio">
                                        <option value="">선택</option>
                                        <option value="스튜디오">캐럿솔루션즈 6F 스튜디오</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 기간</th>
                                <td>
                                    <label><input type="radio" v-model="mod.dtype" @change="comp.changeDaytype" value="oneday"><span class="ml-5 mr-20"> 하루</span></label>
                                    <div class="po-relative dp-inblock w-150px h-32px">
                                        <div v-if="mod.dtype=='period'" class="input-lock"></div>
                                        <CarrotDatePicker v-model.sync="mod.info.tdate" @change="comp.changeDate" class="mr-40 inline-block"></CarrotDatePicker>
                                    </div>
                                    <label><input type="radio" v-model="mod.dtype" @change="comp.changeDaytype" value="period"><span class="ml-5 mr-20"> 기간</span></label>
                                    <div class="po-relative dp-inblock w-300px h-32px">
                                        <div v-if="mod.dtype=='oneday'" class="input-lock"></div>
                                        <CarrotDatePicker v-model.sync="mod.info.sdate" class="inline-block"></CarrotDatePicker>
                                        ~ 
                                        <CarrotDatePicker v-model.sync="mod.info.edate" class="inline-block"></CarrotDatePicker>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 요일</th>
                                <td>
                                    <div class="po-relative dp-inblock w-400px h-32px">
                                        <div v-if="mod.dtype=='oneday'" class="input-lock"></div>
                                        <label v-for="d in comp.dayList" :key="d"><input type="checkbox" v-model="mod.info.days" :value="d"><span class="ml-5 mr-20"> {{ d }}</span></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 시간</th>
                                <td>
                                    <CarrotTime24Select v-model="mod.info.stime"></CarrotTime24Select>
                                     ~ 
                                    <CarrotTime24Select v-model="mod.info.etime"></CarrotTime24Select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 인원</th>
                                <td>
                                    <input type="text" v-model.number="mod.info.count" class="w-100px txt-right">
                                    <span class="ml-5">명</span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 과정명 (용도)</th>
                                <td>
                                    <input type="text" v-model.trim="mod.info.title" class="w-100per" maxlength="100">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 회사명</th>
                                <td>
                                    <CarrotCompany v-if="mod.is_loaded" v-model:companyname.sync="mod.info.company_name" v-model="mod.info.idx_company" class="w-300px"></CarrotCompany>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-30">
                        <button @click="mod.doCancel" class="btn-default float-left ">취소</button>
                        <button @click="mod.doDelete" class="btn-default float-left ml-10">삭제</button>
                        <button @click="mod.doSubmit" class="btn-default float-right ">저장</button>
                        <div class="clear"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotTime24Select from '@/components/common/CarrotTime24Select.vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'


export default {
    layout: 'ResourceManagement',
    components: {
        CarrotTime24Select,
        CarrotDatePicker,
        CarrotCompany,
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast = useToast()

        const mod = reactive({
            is_loaded : false,

            idx   : 0,
            dtype : "oneday",

            info : {
                studio        : "",
                tdate         : "",
                sdate         : "",
                edate         : "",
                stime         : "07:00",
                etime         : "07:00",
                days          : [],
                title         : "",
                idx_company   : 0,
                company_name  : ""
            },

            doCancel: () => {
                router.push({ name:"ResourceManagement-StudioReservationMonth" });
            },

            doDelete : () => {
                Swal.fire({
                    title : '촬영실/녹음실 예약 수정',
                    text : '삭제하시겠습니까?',
                    showCancelButton: true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            idx   : mod.idx,
                            dtype : "allday",
                            tdate : ""
                        };
                        axios.post('/rest/resourceManagement/delStudioReservation', params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.info("삭제되었습니다.");
                                mod.doCancel();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    idx : mod.idx,
                    studio        : mod.info.studio,
                    sdate         : mod.dtype=='oneday'?mod.info.tdate:mod.info.sdate,
                    edate         : mod.dtype=='oneday'?mod.info.tdate:mod.info.edate,
                    sday          : mod.info.days,
                    stime         : mod.info.stime,
                    etime         : mod.info.etime,
                    count         : mod.info.count+"",
                    title         : mod.info.title,
                    idx_company   : mod.info.idx_company,
                    company_name  : mod.info.company_name
                };

                if( !params.studio ){
                    toast.error("촬영실/녹음실을 선택하세요.");
                    return;
                }
                if( !params.sdate || !params.edate ){
                    toast.error("기간을 선택하세요.");
                    return;
                }
                if( params.sday.length <= 0 ){
                    toast.error("요일을 선택하세요.");
                    return;
                }
                if( !params.stime || !params.etime ){
                    toast.error("시간을 선택하세요.");
                    return;
                }
                if( params.stime >= params.etime ){
                    toast.error("시간을 확인해주세요.");
                    return;
                }
                if( !params.count || params.count.replace(/[^0-9]/gi, '') != params.count || Number(params.count) <= 0 ){
                    toast.error("입원을 정확히 입력하세요.");
                    return;
                }
                if( !params.title ){
                    toast.error("과정명(용도)을 입력하세요.");
                    return;
                }
                if( !params.idx_company && !params.company_name ){
                    toast.error("회사명을 입력하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/modStudioReservation", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '촬영실/녹음실 예약 수정',
                            text : '수정하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-StudioReservationMonth'
                            });
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };
                axios.get('/rest/resourceManagement/getStudioReservationInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        if( mod.info.sdate == mod.info.edate ){
                            mod.dtype = 'oneday';
                            mod.info.tdate   = mod.info.sdate;
                            mod.info.sdate   = "";
                            mod.info.edate   = "";
                        } else {
                            mod.dtype = 'period';
                        }

                        mod.is_loaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
        });

        const comp = reactive({
            classroomList: [],
            dayList: [ '월', '화', '수', '목', '금', '토', '일' ],

            changeDaytype : () => {
                if( mod.dtype == 'oneday' ){
                    mod.info.sdate = '';
                    mod.info.edate = '';
                } else {
                    mod.info.tdate = '';
                }
                mod.info.days = [];
            },

            changeDate: () => {
                mod.info.days = [];
                let td = new Date(mod.info.tdate);
                let day = td.getDay();
                if( day == 0 ) mod.info.days.push(comp.dayList[6])
                else mod.info.days.push(comp.dayList[day-1])
            }
        });

        onMounted(() => {
            // Mounted
            mod.is_loaded = false;

            mod.idx = route.params.idx;
            if ( !mod.idx ) {
                router.go(-1);
            } 

            mod.doSearch();
        });

        return {mod, comp};
    }
}
</script>

<style lang="scss" scoped>
.input-lock {
    position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; background-color:rgba(0,0,0,0.001);
}
</style>